@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.info {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.2);

  &Container {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  &Text {
    flex-wrap: nowrap;
    gap: 6px;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    font-family: $roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 200%;

    &:last-child {
      border: none;
    }
  }

  &BulletPoint {
    margin-top: 5px;
    border-radius: 50%;
    min-height: 7.5px;
    min-width: 7.5px;
    align-self: flex-start;
    background-color: $red;
  }

  &Date {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.5);
  }

  &Link {
    color: $red;
    text-decoration: underline;
  }
}

.dpdlocal {
  background-color: $blue_4;
}
