@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.banner {
  flex-grow: 1;
  padding: 0;
  border-radius: 4px;
  background: #fff;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.2);

  &Api {
    border: 0 !important;
    background: linear-gradient(
      180deg,
      $red 0%,
      #d0012d 19.99%,
      #c30229 39.97%,
      #b70324 59.96%,
      #ab041f 79.95%,
      #9f041b 99.93%
    );
  }

  &Container {
    min-height: 11rem;
    background: radial-gradient(
      circle farthest-corner at 5% -15%,
      rgba(220, 0, 50, 0.1) 39%,
      rgba(220, 0, 50, 0.1) 39%,
      #fff 39%
    );
    border-radius: 4px;

    &dpdlocal {
      background: radial-gradient(
        circle farthest-corner at 5% -15%,
        rgba(0, 154, 223, 0.1) 39%,
        rgba(0, 154, 223, 0.1) 39%,
        #fff 39%
      );
    }

    &Api {
      background: radial-gradient(
        circle farthest-corner at 5% -15%,
        rgba(255, 255, 255, 1) 39%,
        rgba(255, 255, 255, 1) 39%,
        rgba(0, 0, 0, 0.1) 39%
      );
    }
  }
}

.greeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;

  &Name {
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
  }

  &Message {
    font-size: 26px;
    line-height: 32px;
  }
}

.promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: $grid-gutter-width;
  gap: 1rem;

  @media (max-width: 575px) {
    padding: 0 1rem 1rem;
  }

  &Message {
    flex-direction: column;
  }

  &Text {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;

    &Highlighted {
      font-size: 26px;
      line-height: 30.47px;

      &Api {
        color: $white;
      }
    }

    &Api {
      color: $white;
    }
  }

  &Button {
    text-transform: capitalize;

    &Api {
      background: #fff;
      color: #000;
      border: 0;
    }

    &Api:hover {
      background: #fff;
      color: #000;
      border: 0;
    }
  }
}

.image {
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &Pic {
    max-width: 19rem;
    height: 11rem;
    border-radius: 4px;
  }
}
