@import "../../../../styles/globals";

.integration {
  color: $brown;
  flex-grow: 1;
  padding: $grid-gutter-width;
  min-height: 17rem;

  &Wrapper {
    padding: $grid-gutter-width;
    gap: 1rem;
  }

  &Title {
    font-size: 18px;
    line-height: 21px;
  }

  &Text {
    margin: 18px 0 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
}

.available {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: $grid-gutter-width * 2;

  &Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  &Title {
    font-size: 26px;
    line-height: 30px;
    text-transform: capitalize;
  }

  &Text {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }
}
