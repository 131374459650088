.modal {
  &Content {
    box-shadow:
      0 11px 15px 0 rgb(0 0 0 / 20%),
      0 9px 46px 0 rgb(0 0 0 / 12%),
      0 24px 38px 0 rgb(0 0 0 / 14%) !important;
    border-radius: 8px !important;
  }

  &Header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid #dee2e6;
    color: #000;
    font-weight: 500;
    font-size: 18px;
  }

  &Body {
    position: relative;
    flex: 1 1 auto;
    padding: 16px 24px;
  }

  &Icon {
    width: 52px;
    height: 52px;
  }

  &Wrapper {
    padding-left: 16px;
  }

  &Title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }

  &Footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: 1px solid #dee2e6;

    button {
      margin: 0;
    }
  }

  &Buttons {
    gap: 15px;
    margin: 0;
  }
}
