@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  min-width: 11rem;
  height: 11rem;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.2);
}

.link {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle farthest-corner at 100% 0%,
    #f5f5f5 30%,
    #f5f5f5 35%,
    #eee 35%,
    rgba(0, 0, 0, 0.1) calc(35% + 1px),
    #fff 20%
  );
  cursor: pointer;

  &Title {
    font-size: 16px;
    line-height: 140%;
    text-transform: capitalize;
    color: #000;
    margin: 27px 7px 17px 16px;
    width: 60%;
  }

  &Icon {
    align-self: flex-end;
    width: 3rem;
    height: 3rem;
    margin: 5%;
  }
}
