@import "../../../../styles/globals";

.modal {
  &Content {
    border-radius: 8px;
    box-shadow:
      0 24px 38px rgba(0, 0, 0, 0.14),
      0 9px 46px rgba(0, 0, 0, 0.12),
      0 11px 15px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    width: 31rem;
    height: 15rem;
  }

  &Header {
    border-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $black;
  }

  &Body {
    display: flex;
    gap: 1rem;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }

  .warningIcon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .modalFooter {
    background-color: $white;
    border-top: 0;
    border-radius: 8px;
  }
}
