@import "../../../../styles/globals";

.leftCol {
  padding-right: 45px;
}

.rightCol {
  padding-left: 45px;
}

.leftCol > * {
  margin-bottom: 28px;
}

.explanation {
  color: $black_medium;
  font-family: $roboto;
  font-size: 10px;
  line-height: 18px;
}
