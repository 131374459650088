@import "../../../../styles/globals";

.explanation {
  color: $red;
  font-family: $roboto;
  font-size: 10px;
  line-height: 18px;
  margin-top: -17px;
}

.multiline {
  min-height: 5rem;
}
