@import "../../styles/globals";

.modal {
  &Header {
    border-bottom: 0 none;
    padding: 16px 24px 0;

    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    border-radius: 8px;
  }

  &Body {
    display: flex;
    padding: 4px 8px 0;
  }

  &Icon {
    width: 52px;
    height: 52px;
  }

  &Wrapper {
    padding-left: 16px;
  }

  &Title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }

  &Content {
    font-size: 14px;
    line-height: 150%;
    box-shadow:
      0 11px 15px 0 rgb(0 0 0 / 20%),
      0 9px 46px 0 rgb(0 0 0 / 12%),
      0 24px 38px 0 rgb(0 0 0 / 14%);
    border-radius: 8px;
  }

  &Footer {
    border-top: 0 none;
    padding: 15px 24px 16px;

    button {
      margin: 0;
    }
  }
}
