.cards {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &Medium {
    row-gap: 1rem;
    padding-bottom: 0.5rem;
  }

  &Small {
    row-gap: 1rem;
  }
}

.linkStyle {
  text-decoration: none;
}
