@import "../../../../../../styles/globals";

.statusIndicator {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.installText {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  padding-top: 4px;
  color: black;
}

.helpText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  /* Black/black-67 */

  color: rgba(0, 0, 0, 0.67);
}

.infoIcon {
  margin-top: -4px;
}

.linkIcon {
  margin-top: -4px;
}

.tutorial {
  padding-left: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  text-decoration-line: underline;

  /* System/Action/action-blue */

  color: #438ded;
}

.tutorialIcon {
  //margin-top: -6px;
  padding-left: 10px;
}

.printerButton {
  &Icon {
    margin-left: 5px;
  }
}
